/* GENERAL */
html {
  scroll-behavior: smooth;

  @apply
    antialiased;

  * {
    @apply
      font-sans
      font-normal
      text-base;
  }
}

::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  @apply
    bg-primary-blue;
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  @apply
    appearance-none;
}

h1, h2, h3, h4, h5, h6 {
  @apply
    font-sans;
}

h1 {
  @apply
    text-2xl
    font-bold;
}

h2 {
  @apply
    text-xl
    font-bold;
}

h3 {
  @apply
    text-xl
    font-bold;
}

h4 {
  @apply
    text-lg
    font-bold;
}

h5 {
  @apply
    text-base
    font-bold;
}

h6 {
  @apply
    text-sm
    font-bold;
}

b,
strong {
  @apply
    font-bold;
}
small {
  font-size: 80%;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

button:focus,
select:focus,
[type='button']:focus,
[type='reset']:focus,
[type='submit']:focus {
  @apply
    outline-none;
}

.page-view {
  min-height: 85vh;
  
  @apply
    pb-10
    pt-10;

  > .container {
    @apply
      mx-auto
      px-4;
  }

  .page-title {
    @apply
      text-3xl
      font-bold
      mb-10
      mt-10;

    > svg {
      @apply
        inline-block
        align-baseline
        transform
        scale-150
        translate-x-0.5;
    }

    @screen lg {
      @apply
        text-4xl;
    }
  }

  .subtitle {
    @apply
      text-primary-blue
      text-xl;
  }
}

#main-header {
  @apply
    bg-primary-blue
    pt-2
    pb-1
    px-4
    text-center
    shadow-lg;

  h2 {
    a {
      @apply
      text-primary-orange
        text-sm;

      > svg {
        @apply
          inline-block
          align-sub;
      }

      b {
        @apply
          text-white
          text-lg
          inline-block
          align-baseline;
      }
    }
  }

  .logout-btn {
    @apply
      absolute
      right-1
      top-2
      text-primary-lblue
      basic-transition;

    &:hover {
      @apply
        text-white;
    }
  }
}

.main-alert {
  @apply
    block
    bg-primary-lblue
    text-white
    text-center
    py-2
    px-4
    fixed
    inset-x-0
    top-0
    shadow-md
    bg-opacity-95
    z-20;

  &.error {
    @apply
      bg-red-600;
  }
}

#main-footer {
  @apply
    py-4
    px-4
    text-center
    border-t
    relative
    z-10
    border-primary-gray;

  small {
    @apply
      text-primary-black
      opacity-50
      text-xxs
      max-w-md
      block
      mx-auto
      leading-tight;
  }
}

.logout {
  @apply
    absolute
    left-4
    bottom-2
    uppercase
    text-xxs
    text-primary-lblue
    tracking-widest;

  b {
    @apply
      text-xxs
      basic-transition;
  }

  &:hover {
    b {
      @apply
        text-primary-blue;
    }
  }
}

.breadcrumbs {
  li {
    @apply
      inline-block
      text-sm
      text-primary-lblue;

    a {
      @apply
        text-sm
        font-bold;

      &:hover {
        @apply
          underline;
      }
    }

    &::after {
      content: '›';

      @apply
        inline-block
        mr-1;
    }

    &:last-of-type {
      &::after {
        @apply
          hidden;
      }
    }
  }
}

.divider {
  @apply
    bg-primary-blue
    text-white
    py-5
    shadow-xl
    z-10
    relative
    text-center;

  h3 {
    @apply
      uppercase
      tracking-widest;
  }
}

.disabled {
  @apply
    pointer-events-none
    opacity-50;
}

.fade-enter-active,
.fade-leave-active {
  @apply
    basic-transition;
}

.fade-enter-from,
.fade-leave-to {
  @apply
    opacity-0;
}

.page-enter-active,
.page-leave-active {
  @apply
    basic-transition;
}

.page-enter-from,
.page-leave-to {
  @apply
    opacity-0;
}

.alert-enter-active,
.alert-leave-active {
  @apply
    basic-transition
    transform;
}

.alert-enter-from,
.alert-leave-to {
  @apply
    opacity-0
    -translate-y-10;
}
